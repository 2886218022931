<template>
  <v-card flat>
    <v-toolbar dense flat>
      <btnmenuitem title="Acompanhamento" idb="duo" icon="chalkboard-teacher" class="me-3" :itens="itensAcompanhamento" @click="itemClick"/>
      <btnmenuitem title="Distribuição" idb="duo" icon="target" class="me-3" @click="$emit('modelToggle','distribuicao')"/>
      <btnmenuitem title="Cards" idb="duo" icon="chart-line" class="me-3" :itens="itensArea" @click="itemClick"/>
      <btnmenuitem title="Importação" idb="duo" icon="upload" class="me-3" @click="$emit('modelToggle','importacao')"/>
    </v-toolbar>
  </v-card>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import btnlocked from "./btn-locked.vue";
import btnmenuitem from "./btn-menu-item.vue";

export default {
  name: "topmenu",
  components: { btnlocked, btnmenuitem },
  props: [],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      itensArea: [
        { title: "Metas x Questões", idb: "duo", icon: "chart-line", target: "metaquestoes" },
        { title: "UCs x Metas", idb: "duo", icon: "chart-line", target: "ucsmetas" },
        { title: "UCs x Professores", idb: "duo", icon: "chart-line", target: "ucsprofs" },
      ],
      itensAcompanhamento: [
        { title: "Visualização das encomendas", idb: "duo", icon: "chart-line", target: "visualizacao" },
        { title: "Entrega das encomendas", idb: "duo", icon: "chart-line", target: "entregas" },
      ]
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //self.loading = true;
    //rdb.ref('').on('value',function(snapshot) {
    //  self.algo = snapshot.val();
    //  console.log("self.algo",self.algo);
    //  self.build();
    //});
  },
  methods:{

    build() {
      var self = this;
    },

    fazAlgo() {
      console.log("fazAlgo");
    },

    itemClick(value) {
      this.$emit('modelToggle',value);
    }
  }
}
</script>

<style scoped>
</style>
